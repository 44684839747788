<template>
  <div
    class="block block--about-the-program block--slash"
    id="about-the-program"
  >

    <slash v-parallax="0.5" />

    <div class="row">

      <small>About Pioneers</small>
      <h2>This is a programme like <br>no other. <span class="highlight"> We're open to <br>everyone.</span> </h2>

    </div>

    <div class="row row--indent row--arrow">

      <p>Welcome to Pioneers. m/SIX’s newly expanded talent scheme driven at finding the most diverse talent in the UK. We’re searching for game-changers who perhaps have never considered the world of media their potential home. In return you’ll receive world class on-the-job training, helping to shape your future. You’ll earn, learn and have fun inside the fastest growing media agency in the world.</p>

    </div>

    <div class="row row--full-right">

      <Video />

    </div>

    <div class="row row--indent row--arrow">

      <p>We’re looking for raw talent. We don’t care where you’ve come from or what you’re doing right now. What we do care about is your passion and enthusiasm to learn. If selected for one of the six places, you’ll have the chance to work on live briefs and be exposed to all the wonderful happenings within the agency. From live client work and pitching, through to workshops, lunch ‘n’ learns and agency socials.</p>

    </div>

  </div>
</template>

<script>
import Video from "@/components/Video";
import Slash from "@/components/Slash";

export default {
  name: "AboutTheProgramme",
  components: {
    Video,
    Slash
  }
};
</script>
