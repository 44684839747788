<template>
  <div
    class="video"
    ref="videoContainer"
    lazy="/images/video-thumbnail.jpg"
    :class="{visible: overElement, 'video-playing': videoPlaying, 'video-paused': videoPaused}"
    @click="playVideo"
  >

    <div
      class="video-cursor"
      ref="videoCursor"
      :style="cursorStyles"
    >

      <span>PLAY ( <span id="videoTime">03<span class="video-cursor__colon">:</span>20</span> )</span>

    </div>

    <!-- <video
      src="https://content.mindshareapps.com/media/Global.mp4"
      poster="/images/video-thumbnail.jpg"
      autoplay="false"
      controls
      ref="video"
      @click.stop.prevent="handleVideoClick"
    ></video> -->

    <div
      class="video__iframe"
      poster="/images/video-thumbnail.jpg"
      ref="video"
      @click.stop.prevent="handleVideoClick"
    ></div>

  </div>

</template>

<script>
import Player from "@vimeo/player";

export default {
  name: "Video",
  data() {
    return {
      transformX: "-50%",
      transformY: "-50%",
      tempTransformX: "-50%",
      tempTransformY: "-50%",
      videoPlaying: false,
      overElement: false,
      videoPaused: null,
      player: null
    };
  },
  computed: {
    cursorStyles() {
      return {
        transform:
          "translateX(" +
          this.transformX +
          "px" +
          ") translateY(" +
          this.transformY +
          "px" +
          ")"
      };
    }
  },
  methods: {
    playVideo() {
      this.videoPlaying = true;

      this.player.play();

      if (window.GOOD_PERFORMANCE) {
        console.log(document.querySelector(".video__iframe"));
        document
          .querySelector(".video__iframe")
          .addEventListener("click", function() {
            console.log("mousedown");
          });
        // document
        //   .getElementsByTagName("iframe")[0]
        //   .addEventListener("mousemove", this.onmousemove);
      }
      //Move the custom cursor to where to mouse cursor is because we're changing it's size
      let cursorRect = this.$refs.videoCursor.getBoundingClientRect();

      let x = cursorRect.width / 2;
      let y = cursorRect.height / 2;

      this.tempTransformX = this.transformX + x;
      this.tempTransformY = this.transformY + y;

      this.moveCursor();
    },
    handleVideoClick() {
      // let video = this.$refs.video;

      let $this = this;

      if (this.videoPaused) {
        this.player.play();
      } else {
        this.player.pause();
      }

      this.player.getPaused().then(function(paused) {
        $this.videoPaused = paused;
      });
    },
    moveCursor() {
      this.transformX = this.tempTransformX;
      this.transformY = this.tempTransformY;

      this.requestAnimationFrame = requestAnimationFrame(this.moveCursor);
    },
    onmousemove(e) {
      if (this.$refs.videoContainer && this.$refs.videoCursor) {
        console.log("moving");
        var videoRect = this.$refs.videoContainer.getBoundingClientRect();
        var cursorRect = this.$refs.videoCursor.getBoundingClientRect();

        var x = e.clientX - videoRect.left - cursorRect.width / 2;
        var y = e.clientY - videoRect.top - cursorRect.height / 2;

        this.tempTransformX = x;
        this.tempTransformY = y;

        if (
          e.clientX > videoRect.left &&
          e.clientX < videoRect.left + videoRect.width &&
          e.clientY > videoRect.top &&
          e.clientY < videoRect.top + videoRect.height
        ) {
          this.overElement = true;
        } else {
          this.overElement = false;
        }
      }
    }
  },
  mounted() {
    if (window.GOOD_PERFORMANCE) {
      document.addEventListener("mousemove", this.onmousemove);

      this.requestAnimationFrame = requestAnimationFrame(this.moveCursor);
    }

    this.player = new Player(this.$refs.video, {
      id: 472250632,
      autoplay: false
    });
  }
};
</script>
