<template>
  <div
    class="block block--what-youll-be-doing"
    id="what-youll-be-doing"
  >

    <div class="row">

      <small>What you'll be doing</small>
      <h2> Get a buddy <br> Get a mentor <br> <span class="highlight">Focus on learning</span></h2>

    </div>

    <div class="row row--indent row--arrow">

      <p>Over the six months that you're in the business, you'll be taken in directions you've never been before, as you get to know the inner workings of m/SIX. You'll experience first-hand, how we operate, how we solve problems for clients and how we collaborate in a way that breeds success. In order to ensure that you hit the ground running, we'll provide you with a buddy and mentor to help shape, guide and support you throughout. You will be given a comprehensive introduction to the media landscape, will be trained in industry leading tools and you will also get the opportunity to get industry accreditation to kick start your career. This is an unmissable opportunity if you want to start a career in media! </p>

    </div>

    <!--<div class="row row--indent">

      <diagram />

    </div>-->

  </div>
</template>

<script>
//import Diagram from "./Diagram";
export default {
  name: "WhatYoullBeDoing",
  components: {
   // Diagram
  }
};
</script>
