<template>
  <div
    class="ticker"
    id="ticker"
  >
    <div class="ticker__text-container">
      <p
        v-for="i in 3"
        :key="i"
      >Applications are now closed for m/SIX Pioneers, a 6-month paid opportunity giving you the best media industry education on the planet ↓↓ <!--<a href="#apply-now">Apply now</a>--> ↓↓
      </p>
    </div>

  </div>
</template>

<script>
export default {
  name: "Ticker",
  props: {
    msg: String
  }
};
</script>
