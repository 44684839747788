export default function checkPerf() {

    const PERF_BAD = 0;
    const PERF_LOW = 1;
    const PERF_GOOD = 2;
    const PERF_HIGH = 3;


    function getPerfs() {

        const start = (window.performance || Date).now();
        for (let i = 0, array = []; i < 20000; i++) {
            array.push(Math.pow(Math.sin(Math.random()), 2));
        }
        const end = (window.performance || Date).now();
        return end - start;
    }

    const timing = getPerfs()

    if (timing < 7) {
        window.PERF = PERF_HIGH
        window.GOOD_PERFORMANCE = true;
    } else if (timing < 14) {
        window.PERF = PERF_GOOD
        window.GOOD_PERFORMANCE = true;
    } else if (timing < 22) {
        window.PERF = PERF_LOW
        window.GOOD_PERFORMANCE = false;
    } else {
        window.PERF = PERF_BAD
        window.GOOD_PERFORMANCE = false;
    }

    const body = document.querySelector('body');

    if (window.GOOD_PERFORMANCE) {
        body.classList.add('good-performance');
    } else {
        body.classList.add('bad-performance');
    }

}