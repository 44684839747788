<template>
  <div
    class="block block--apply-now"
    id="apply-now"
  >

    <div class="row">

      <!--<small>Apply now</small>-->

      <h2> <span class="highlight">Applications are now<br> closed</span> across three<br> different tracks.</h2>

    </div>

    <div class="row row--indent row--arrow">

      <h4>Digital Native</h4>

      <p>If you’re interested in our Pioneers scheme, but not sure which route to apply for, then the Digital route is for you. During the six months that you’re a Pioneer with us, you’ll be shown the inner workings of m/SIX, spend time in a variety of departments and you’ll experience working on live client work – whether that’s in a problem-solving capacity, as an account handler, a project manager or within social media or analytics is up to you. We’re not looking for the finished article, and you won’t be held in a specific grouping if your strengths lie elsewhere – it’s just a useful way of us sorting people into three sections.</p>

      <!--<a
        href="https://docs.google.com/forms/d/e/1FAIpQLSdNkiFjRd0axNx4SI3YQdBM7ey8SU2Mp_LIO4J8nifpuxuLeQ/viewform?usp=sf_link"
        class="apply-now-fake-link"
        target="_blank"
      >Apply now</a>-->

    </div>

    <div class="row row--indent row--arrow">

      <h4>Analyst</h4>

      <p>m/SIX is a business that helps our clients improve their marketing and media effectiveness. We use marketing and media data to measure how things work, to find relationships and patterns in data and to predict how different types of marketing investment will work in the future. With our clients often investing millions of pounds, just a small increase in efficiency can produce large cost savings. We look for people who are curious about data, love working with numbers and want to work toward a future with us in helping our clients improve the way their marketing works. Sound like you? Then get applying!</p>

      <!--<a
        href="https://docs.google.com/forms/d/e/1FAIpQLSc_XIJ4djkE8_M7NztOe2wGRApf_CW-1RBya7CtlbTebn3M9w/viewform?usp=sf_link"
        class="apply-now-fake-link"
        target="_blank"
      >Apply now</a>-->

    </div>

    <div class="row row--indent row--arrow row--no-border">

      <h4>Problem Solver</h4>

      <p>We’re an innovative and entrepreneurial bunch at m/SIX. As such, our clients regularly ask us to help them solve a particular problem or to help come up with an exciting idea to help reach a specific demographic of the population. If you like to stretch lateral thinking, use your creative brain power to win an argument or demonstrate an idea through the use of new technology, then the Problem Solver route might be appropriate for you. This is the route where boundaries only exist where you put them!</p>

      <!--<a
        href="https://docs.google.com/forms/d/e/1FAIpQLSeki3C2RpezdAPx7pAksuV0mOWRX-hOv5kRZvbz1ZLRGziwOA/viewform?usp=sf_link"
        class="apply-now-fake-link"
        target="_blank"
      >Apply now</a>-->

    </div>

    <question-block />

  </div>
</template>

<script>
import QuestionBlock from "@/components/QuestionBlock";

export default {
  name: "ApplyNow",
  components: {
    QuestionBlock
  },
  methods: {
    selectAnswer(index) {
      this.$parent.$emit("selectedAnswer", index);
    }
  }
};
</script>
