<template>
  <div
    class="question-block row row--full-right-bleed"
    id="question-block"
  >

    <div class="row row--full-right-bleed-inner">

      <h3>Not sure which one is for you?<br> <span class="highlight">Answer this...</span></h3>

      <small>Select one answer</small>

      <p>You’ve been tasked with making a cake for your friend’s birthday. <br>
        What do you do first?</p>

      <div class="question-block__answer-container">

        <div
          class="question-block__answer"
          :class="{selected: selectedAnswerIndex === 1}"
          @click="selectAnswer(1)"
        >

          <span>A</span>

          <span>I get online and research a load of recipes.</span>

          <span>Selected</span>

        </div>

        <div
          class="question-block__answer"
          :class="{selected: selectedAnswerIndex === 2}"
          @click="selectAnswer(2)"
        >

          <span>B</span>

          <span>I figure out what existing equipment and ingredients I have.</span>

          <span>Selected</span>

        </div>

        <div
          class="question-block__answer"
          :class="{selected: selectedAnswerIndex === 3}"
          @click="selectAnswer(3)"
        >

          <span>C</span>

          <span>I buy one from a shop and pretend I made it.</span>

          <span>Selected</span>

        </div>

      </div>

      <answer-block
        v-if="selectedAnswerIndex"
        :selected-answer-index="selectedAnswerIndex"
      />

    </div>

  </div>
</template>

<script>
import AnswerBlock from "@/components/AnswerBlock";

export default {
  name: "QuestionBlock",
  components: {
    AnswerBlock
  },
  data() {
    return {
      selectedAnswerIndex: null
    };
  },
  methods: {
    selectAnswer(index) {
      this.selectedAnswerIndex = index;

      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 100);
    }
  },
  mounted() {
    let $this = this;
    $this.$parent.$parent.$on("selectedAnswer", index => {
      $this.selectAnswer(index);
    });
  }
};
</script>
