<template>
  <main
    id="app"
    data-scroll-container
  >

    <ticker />

    <div
      class="nav__hamburger"
      @click="toggleNavOpen"
    >
      <span></span>
      <span></span>
      <span></span>
    </div>

    <div class="nav__side-logo" />

    <aside
      class="nav__container"
      id="navContainer"
    >

      <Nav :element-in-view="elementInView" />

      <!--<countdown />-->

    </aside>

    <div class="container">

      <hero data-nav-section />

      <about-the-programme data-nav-section />

      <who-is-this-for data-nav-section />

      <what-youll-be-doing data-nav-section />

      <who-we-are data-nav-section />

      <application-process data-nav-section />

      <programme-partners data-nav-section />

      <apply-now data-nav-section />

    </div>

  </main>
</template>

<script>
import Ticker from "./components/Ticker";
import Hero from "./components/Hero";
import AboutTheProgramme from "./components/AboutTheProgramme";
import WhoIsThisFor from "./components/WhoIsThisFor";
import WhatYoullBeDoing from "./components/WhatYoullBeDoing";
import WhoWeAre from "./components/WhoWeAre";
import ApplicationProcess from "./components/ApplicationProcess";
import ProgrammePartners from "./components/ProgrammePartners";
import ApplyNow from "./components/ApplyNow";
import Nav from "./components/Nav";
// import Countdown from "./components/Countdown";

export default {
  name: "App",
  data() {
    return {
      scroll: null,
      elementInView: "hero",
      navOpen: false
    };
  },
  components: {
    Ticker,
    Hero,
    AboutTheProgramme,
    WhoIsThisFor,
    WhatYoullBeDoing,
    WhoWeAre,
    ApplicationProcess,
    ProgrammePartners,
    ApplyNow,
    Nav,
    // Countdown
  },
  methods: {
    toggleNavOpen() {
      const body = document.getElementsByTagName("body")[0];

      if (this.navOpen) {
        this.closeNav();
      } else {
        this.navOpen = true;
        body.classList.add("noscroll", "nav-open");
      }
    },
    closeNav() {
      const body = document.getElementsByTagName("body")[0];

      this.navOpen = false;
      body.classList.remove("noscroll", "nav-open");
    },
    applyLazy(el) {
      const imageUrl = el.getAttribute("lazy");
      if (el instanceof window.HTMLImageElement) {
        el.setAttribute("src", imageUrl);

        el.onload = function() {
          el.setAttribute("loaded", true);
        };
      } else {
        el.style.backgroundImage = `url(${imageUrl})`;

        el.onload = function() {
          el.setAttribute("loaded", true);
        };
      }
      return true;
    },
    initLazyLoadObserver() {
      const $this = this;
      const lazyLoadObserver = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              $this.applyLazy(entry.target);
              observer.unobserve(entry.target);
            }
          });
        },
        {
          threshold: 0.2,
          rootMargin: "0px 0px 200px 0px"
        }
      );

      document.querySelectorAll("[lazy]").forEach(img => {
        lazyLoadObserver.observe(img);
      });
    },
    initScrollObserver() {
      const $this = this;
      const scrollObserver = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              $this.elementInView = entry.target.id;
            }
          });
        },
        {
          threshold: 0.2
        }
      );

      document.querySelectorAll("[data-nav-section]").forEach(element => {
        scrollObserver.observe(element);
      });
    },
    initTickerObserver() {
      const body = document.getElementsByTagName("body")[0];

      const tickerObserver = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              body.classList.remove("ticker-scrolled");
            } else {
              body.classList.add("ticker-scrolled");
            }
          });
        },
        {
          threshold: 0
        }
      );

      document.querySelectorAll(".ticker").forEach(element => {
        tickerObserver.observe(element);
      });
    },
    initObservers() {
      if ("IntersectionObserver" in window) {
        this.initLazyLoadObserver();
        this.initScrollObserver();

        if (window.GOOD_PERFORMANCE) {
          this.initTickerObserver();
        }
      }
    },
    initSmoothScroll() {
      document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener("click", function(e) {
          e.preventDefault();

          //Only smooth scroll to element if it's not the same as we're currently on
          if (
            this.getAttribute("href") &&
            window.location.hash !== this.getAttribute("href")
          ) {
            window.location.hash = this.getAttribute("href");
            document.querySelector(this.getAttribute("href")).scrollIntoView({
              behavior: "smooth"
            });
          }
        });
      });
    },
    initMobileNavLinksEvents() {
      const $this = this;

      const links = document.querySelectorAll("nav a");

      links.forEach(element => {
        element.addEventListener("click", () => {
          $this.closeNav();
        });
      });
    }
  },
  mounted() {
    this.initSmoothScroll();
    this.initObservers();
    this.initMobileNavLinksEvents();

    //TODO CHECK for device speed, don't do fancy effects if slow
  }
};
</script>
