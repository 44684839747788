<template>
  <div
    class="slash"
    v-if="goodPerformance"
  >

  </div>
</template>

<script>
export default {
  name: "Slash",
  components: {},
  computed: {
    goodPerformance() {
      return window.GOOD_PERFORMANCE;
    }
  },
  mounted() {}
};
</script>
