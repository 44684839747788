import Vue from "vue";
import App from "./App.vue";
import "./assets/scss/app.scss";
import "./assets/js/polyfills";
import checkPerf from "./assets/js/performanceCheck";
import VueParallaxJs from "vue-parallax-js";
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-EQLPEBPFNG" }
});

checkPerf();
Vue.config.productionTip = false;
Vue.use(VueParallaxJs);

new Vue({
  render: h => h(App)
}).$mount("#app");