<template>
  <div
    class="block hero"
    id="hero"
  >

    <div class="hero__slash-container">

      <slash
        v-parallax="1.1"
        ref="slash1"
      />

      <slash
        v-parallax="1.4"
        ref="slash2"
      />

    </div>

    <div
      class="hero__logo"
      ref="logo"
    >

      <img
        alt=""
        src="/images/hero/logo.svg"
      >

      <div
        class="hero__image-mask"
        ref="logoMask"
      ></div>

    </div>

    <div class="row">

      <div class="hero__text">

        <div class="hero__text-line">
          <h1> <span class="highlight">Future game</span></h1>
        </div>

        <div class="hero__text-line">
          <h1> <span class="highlight">changers of media,</span></h1>
        </div>

        <div class="hero__text-line">
          <h1>we want you</h1>
        </div>

      </div>

    </div>

    <div class="hero__image hero__image--center">
      <img
        alt=""
        src=""
        ref="centerImage"
        lazy="/images/hero/center.gif"
      >
      <div
        class="hero__image-mask"
        ref="centerMask"
      ></div>
    </div>

    <div
      class="hero__image hero__image--top-right"
      ref="topRight"
    >
      <img
        alt=""
        lazy="/images/hero/top-right.jpg"
      >
      <div
        class="hero__image-mask"
        ref="topRightMask"
      ></div>
    </div>

    <div
      class="hero__image hero__image--bottom-right"
      ref="bottomRight"
    >
      <img
        alt=""
        lazy="/images/hero/bottom-right.jpg"
      >
      <div
        class="hero__image-mask"
        ref="bottomRightMask"
      ></div>
    </div>

    <div
      class="hero__image hero__image--bottom-left"
      ref="bottomLeft"
    >
      <img
        alt=""
        lazy="/images/hero/bottom-left.jpg"
      >
      <div
        class="hero__image-mask"
        ref="bottomLeftMask"
      ></div>
    </div>

  </div>
</template>

<script>
import Slash from "@/components/Slash";
import { gsap, Power2, Expo } from "gsap/all";

export default {
  name: "Hero",
  components: {
    Slash
  },
  data() {
    return {
      body: null,
      gifLoaded: false,
      slash1Y: null,
      slash2Y: null,
      imageSrcs: {
        centre: "/images/hero/center.gif",
        bottomLeft: "/images/hero/bottom-left.jpg",
        bottomRight: "/images/hero/bottom-right.jpg",
        topRight: "/images/hero/top-right.jpg"
      }
    };
  },
  computed: {
    goodPerformance() {
      return window.GOOD_PERFORMANCE;
    }
  },
  methods: {
    initAnimation() {
      const {
        logo,
        logoMask,
        center,
        topRight,
        bottomRight,
        bottomLeft,
        centerMask,
        topRightMask,
        bottomLeftMask,
        bottomRightMask,
        centerImage
      } = this.$refs;

      const slash1 = this.$refs.slash1.$el;
      const slash2 = this.$refs.slash2.$el;

      const ticker = document.getElementById("ticker");
      const tickerText = document.querySelector(".ticker__text-container");
      const navContainer = document.getElementById("navContainer");
      const navHamburger = document.querySelector(".nav__hamburger");
      const sideLogo = document.querySelector(".nav__side-logo")

      const heroTextSpans = document.querySelectorAll(".hero__text-line h1");

      let imagesTimeline = gsap.timeline({
        paused: true,
        onStart: () => {
          //Scroll to top of page on load and prevent scroll
          if (!window.location.hash) {
            window.scrollTo(0, 0);
          }

          this.body.classList.add("noscroll", "animating");

          this.body.classList.remove("animation-done");
        },
        onComplete: () => {
          //Enable scroll after animation finishes
          this.body.classList.remove("noscroll", "animating");

          this.body.classList.add("animation-done");
        }
      });

      let $this = this;

      //When the gif has loaded continue with animation
      centerImage.addEventListener("load", () => {
        $this.gifLoaded = true;

        imagesTimeline.resume();
      });

      const navYDuration = 0.5;
      const navYDelay = 0;
      const navYEasing = Power2.easeOut;

      const logoSlashYDuration = 0.8;

      const heroTextSpansDelay = -0.2;
      const heroTextSpansDuration = 0.3;

      const imageMasksXDelay = 0.3;
      const imageMasksXEasing = Expo.easeOut;
      const imageMasksXDuration = 0.5;

      //Wrapped the animation init in a timeout so that the slashes parallax are init before meaning I can get their translateY value. Not too pleased about this, but seems to work. It might take longer on slower devices, but if it did it would only animate the slashes from slightly the wrong place and I'm not playing the animation on slow devices anyway. Will revisit if need to.
      setTimeout(function() {
        $this.slash1Y = $this.getTranslateY(slash1);
        $this.slash2Y = $this.getTranslateY(slash2);

        imagesTimeline
          .from([logoMask, logo, center, topRight, bottomRight, bottomLeft], {
            opacity: 0,
            duration: 0,
            onComplete: () => {}
          })
          .fromTo(
            logoMask,
            {
              width: "100%"
            },
            {
              width: 0,
              duration: 2,
              ease: "linear",
              onComplete: () => {
                if (!$this.gifLoaded) {
                  imagesTimeline.pause();
                }
              }
            }
          )

          .from(
            logo,
            {
              top: "50%",
              y: "-50%",
              duration: logoSlashYDuration,
              ease: Expo.easeOut
            },
            "logoSlashY"
          )

          .fromTo(
            slash1,
            {
              y: "50%",
              opacity: 0
            },
            {
              y: $this.slash1Y,
              opacity: 1,
              duration: logoSlashYDuration,
              ease: Expo.easeOut,
              delay: 0.1
            },
            "logoSlashY"
          )

          .fromTo(
            slash2,
            {
              y: "50%",
              opacity: 0
            },
            {
              y: $this.slash2Y,
              opacity: 1,
              duration: logoSlashYDuration,
              ease: Expo.easeOut,
              delay: 0.1
            },
            "logoSlashY"
          );

        //fade text in after bar ticker bar comes
        for (let index = 0; index < heroTextSpans.length; index++) {
          const heroTextSpan = heroTextSpans[index];

          //stops pre animation flash of text
          imagesTimeline.from(heroTextSpan, {
            opacity: 0,
            duration: 0,
            delay: index === 0 ? heroTextSpansDelay : 0.001 * index
          });

          imagesTimeline.from(heroTextSpan, {
            y: "100%",
            duration: heroTextSpansDuration,
            ease: Expo.easeOut
          });
        }

        imagesTimeline
          .to(
            centerMask,
            {
              x: "100%",
              duration: imageMasksXDuration,
              ease: imageMasksXEasing,
              delay: imageMasksXDelay
            },
            "imageMasksX"
          )

          .to(
            topRightMask,
            {
              x: "100%",
              opacity: 0,
              duration: imageMasksXDuration,
              ease: imageMasksXEasing,
              delay: imageMasksXDelay
            },
            "imageMasksX"
          )

          .to(
            bottomLeftMask,
            {
              x: "100%",
              opacity: 0,
              duration: imageMasksXDuration,
              ease: imageMasksXEasing,
              delay: imageMasksXDelay
            },
            "imageMasksX"
          )

          .to(
            bottomRightMask,
            {
              x: "100%",
              opacity: 0,

              duration: imageMasksXDuration,
              ease: imageMasksXEasing,
              delay: imageMasksXDelay
            },
            "imageMasksX"
          )

          .from(
            navContainer,
            {
              opacity: 0,
              transform: "translateY(0)",
              duration: navYDuration,
              ease: navYEasing,
              delay: navYDelay
            },
            "navY"
          )

          .from(
            navHamburger,
            {
              opacity: 0,
              duration: navYDuration,
              ease: navYEasing,
              delay: navYDelay
            },
            "navY"
          )

          .from(
            sideLogo,
            {
              opacity: 0,
              duration: navYDuration,
              ease: navYEasing,
              delay: navYDelay
            },
            "navY"
          )

          .from(
            ticker,
            {
              opacity: 0,
              y: "-50px",
              duration: navYDuration,
              ease: navYEasing,
              delay: navYDelay
            },
            "navY"
          )
          .from(tickerText, {
            opacity: 0,
            duration: navYDuration,
            ease: navYEasing,
            delay: -0.1
          });

        setTimeout(() => {
          //Make sure the window is fully scrolled top.
          window.scrollTo(0, 0);
          imagesTimeline.resume();
        }, 1000);

        //Restart animation if changing from desktop to mobile so elements line up correctly
        window.addEventListener("resize", () => {
          if (
            (window.innerWidth > 769 && $this.previousWidth < 769) ||
            (window.innerWidth < 769 && $this.previousWidth > 769)
          ) {
            imagesTimeline.restart();
          }

          $this.previousWidth = window.innerWidth;
        });
      }, 1);
    },
    //Get translateY value from translate3d style property
    getTranslateY(element) {
      return element.style.transform && element.style.transform.split(" ")[1]
        ? element.style.transform.split(" ")[1].replace(",", "")
        : 0;
    }
  },
  mounted() {
    this.body = document.querySelector("body");

    //If hash is set, don't run animation
    if (window.location.hash) {
      //Make sure correct element slides into view on page load
      document.querySelector(window.location.hash).scrollIntoView(true);

      this.body.classList.add("animation-done");

      //If we're on a good device, try and run animation
    } else if (this.goodPerformance) {
      this.initAnimation();

      //else just trigger animation done
    } else {
      this.body.classList.add("animation-done");
    }
  }
};
</script>
