<template>
  <div
    class="block block--programme-partners"
    id="programme-partners"
  >

    <slash v-parallax="1.3" />

    <slash v-parallax="0.8" />

    <div class="row">

      <small>Who we're working with</small>
      <h2>Programme partners</h2>

    </div>

    <div class="row">

      <div class="grid  grid--3">

        <div class="grid__item">

          <img
            src=""
            alt="Princes Trust"
            lazy="/images/partners/princes-trust.png"
          >

        </div>

        <div class="grid__item">

          <img
            src=""
            alt="Ambitious about autism"
            lazy="/images/partners/autism.png"
          >

        </div>

        <div class="grid__item">

          <img
            src=""
            alt="Facebook"
            lazy="/images/partners/facebook.png"
          >

        </div>

        <div class="grid__item">

          <img
            src=""
            alt="Collective"
            lazy="/images/partners/collective.png"
          >

        </div>

        <div class="grid__item">

          <img
            src=""
            alt="Mayor's Fund for London"
            lazy="/images/partners/mayors-fund.png"
          >

        </div>

      </div>

    </div>

  </div>
</template>

<script>
import Slash from "@/components/Slash";

export default {
  name: "WhoIsThisFor",
  components: {
    Slash
  }
};
</script>
