<template>
  <div
    class="block block--who-we-are block--slash"
    id="who-we-are"
  >

    <slash v-parallax="1.9" />

    <slash v-parallax="1.1" />

    <div class="row">

      <small>Who are we?</small>

      <h2>
        <span class="highlight"><span class="lowercase">m</span>/SIX is a progressive and entrepreneurial international media network</span> that was born digital.
      </h2>

    </div>

    <div class="who-we-are-image who-we-are-image--top-right">

      <img
        src=""
        alt="Agency"
        lazy="/images/who-we-are/top-right.jpg"
      >

    </div>

    <div class="row row--relative row--full-right-narrow">

      <div class="who-we-are-image who-we-are-image--center">

        <img
          src=""
          alt="Agency"
          lazy="/images/who-we-are/agency.jpg"
        >

      </div>

      <div class="who-we-are-image who-we-are-image--bottom-left">

        <img
          src=""
          alt="Agency"
          lazy="/images/who-we-are/bottom-left.jpg"
        >

      </div>

    </div>

    <div class="row row--indent row--arrow">

      <p> With over 40 offices worldwide, m/SIX is backed by WPP, the world’s largest communications agency network, and embraces a unique ownership structure between The&Partnership and GroupM. m/SIX specialises in driving commercial, as well as audience growth, in today’s data and tech-led media landscape, and is an industry leader in transparency, martech and next-generation audience insight. Some of our clients are listed below.</p>

    </div>

    <div class="row">

      <div class="grid  grid--4">

        <div class="grid__item">

          <img
            src=""
            alt="EA Sports"
            lazy="/images/who-we-are/ea.png"
          >

        </div>

        <!--<div class="grid__item">

          <img
            src=""
            alt="Virgin Money"
            lazy="/images/who-we-are/virgin-money.png"
          >

        </div>-->

        <div class="grid__item">

          <img
            src=""
            alt="Britvic"
            lazy="/images/who-we-are/britvic.png"
          >

        </div>

        <div class="grid__item">

          <img
            src=""
            alt="Toyota"
            lazy="/images/who-we-are/toyota.png"
          >

        </div>

        <div class="grid__item">

          <img
            src=""
            alt="Tate & Lyle"
            lazy="/images/who-we-are/tate.png"
          >

        </div>

        <div class="grid__item">

          <img
            src=""
            alt="Lexus"
            lazy="/images/who-we-are/lexus.png"
          >

        </div>

        <div class="grid__item">

          <img
            src=""
            alt="Princes Trust"
            lazy="/images/partners/princes-trust.png"
          >

        </div>

        <div class="grid__item">

          <img
            src=""
            alt="Talk Talk"
            lazy="/images/who-we-are/talk-talk.png"
          >

        </div>

        <div class="grid__item">

          <img
            src=""
            alt="News UK"
            lazy="/images/who-we-are/news-uk.png"
          >

        </div>

        <div class="grid__item">

          <img
            src=""
            alt="Vitality"
            lazy="/images/who-we-are/vitality.png"
          >

        </div>

        <div class="grid__item">

          <img
            src=""
            alt="London Business School"
            lazy="/images/who-we-are/lbs.png"
          >

        </div>

        <div class="grid__item">

          <img
            src=""
            alt="Fossil"
            lazy="/images/who-we-are/fossil.png"
          >

        </div>

      </div>

    </div>

  </div>
</template>

<script>
import Slash from "@/components/Slash";

export default {
  name: "WhoWeAre",
  components: {
    Slash
  }
};
</script>
