<template>
  <div
    class="block block--who-is-this-for block--slash"
    id="who-is-this-for"
  >

    <slash v-parallax="1.5" />

    <slash v-parallax="1.1" />

    <div class="row">

      <small>Who this is for</small>
      <h2>
        <span class="highlight">NO</span> FIRST/SECOND NAMES <br>
        <span class="highlight">NO</span> CV's <br> <span class="highlight">NO</span> Upper age limit
      </h2>
    </div>

    <div class="row row--indent row--arrow">

      <p>There’s no educational requirements, no upper age limit and we’re hiring without the use of CV’s or even first/second names. Our only prerequisites are that you’re over the age of 18 and that you have the right to live/work in the UK. In return, you’ll get a paid 6 month fixed term contract with the chance to earn the right to a permanent role within the agency at the end of it.</p>

    </div>

  </div>
</template>

<script>
import Slash from "@/components/Slash";

export default {
  name: "WhoIsThisFor",
  components: {
    Slash
  }
};
</script>
