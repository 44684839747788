<template>

  <nav
    class="nav"
    :class="{'open':navOpen}"
  >

    <ul>
      <!-- <li class="nav__item nav__item--logo"></li> -->
      <li
        v-for="(link,index) in links"
        class="nav__item"
        :class="'nav__item--'+link.href"
        :key="index"
      >
        <a
          :href="link.external ? link.href : '#'+link.href"
          :target="link.external ? 'blank' : ''"
          :class="{selected: elementInView === link.href}"
        ><span>{{link.title}}</span></a>
      </li>
    </ul>
  </nav>

</template>

<script>
export default {
  name: "Nav",
  props: {
    elementInView: String
  },
  methods: {},
  data() {
    return {
      navOpen: false,
      links: [
        { href: "hero", title: "Introduction" },
        { href: "about-the-program", title: "About Pioneers" },
        { href: "who-is-this-for", title: "Who this is for" },
        { href: "what-youll-be-doing", title: "What you'll be doing" },
        { href: "who-we-are", title: "Who are we?" },
        { href: "application-process", title: "Application process" },
        { href: "programme-partners", title: "Programme partners" },
        { href: "https://msix-pioneers.s3.eu-west-2.amazonaws.com/Pioneers_Frequently_Asked__Questions_2.pdf", title: "FAQs", external: true},
        //{ href: "apply-now", title: "Apply now" }
      ]
    };
  },
  mounted() {}
};
</script>
