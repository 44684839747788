<template>
  <div
    class="answer-block"
    id="answerBlock"
  >

    <div class="row row--full-right-bleed-inner-inner">

      <h3>Excellent stuff, you’ll be<br> great on our <span class="highlight">( {{selectedAnswer.name}} )</span><br> programme.</h3>

      <!--<h3><a target="_blank" :href="selectedAnswer.url">Apply now</a></h3>-->

    </div>

  </div>
</template>

<script>
export default {
  name: "AnswerBlock",
  data() {
    return {
      answers: [
        {
          index: 1,
          name: "Generalist",
          url: "https://docs.google.com/forms/d/e/1FAIpQLSdNkiFjRd0axNx4SI3YQdBM7ey8SU2Mp_LIO4J8nifpuxuLeQ/viewform?usp=sf_link"
        },
        {
          index: 2,
          name: "Analyst",
          url: "https://docs.google.com/forms/d/e/1FAIpQLSc_XIJ4djkE8_M7NztOe2wGRApf_CW-1RBya7CtlbTebn3M9w/viewform?usp=sf_link"
        },
        {
          index: 3,
          name: "Problem solver",
          url: "https://docs.google.com/forms/d/e/1FAIpQLSeki3C2RpezdAPx7pAksuV0mOWRX-hOv5kRZvbz1ZLRGziwOA/viewform?usp=sf_link"
        }
      ]
    };
  },
  props: {
    selectedAnswerIndex: Number
  },
  computed: {
    selectedAnswer() {
      let filteredAnswers = this.answers.filter(answer => {
        return answer.index === this.selectedAnswerIndex;
      });

      return filteredAnswers[0] ? filteredAnswers[0] : null;
    }
  }
};
</script>
