<template>
  <div
    class="block block--application-process block--slash"
    id="application-process"
  >
    <slash v-parallax="1.5" />

    <slash v-parallax="2" />

    <slash v-parallax="1.2" />

    <div class="row">
      <small>Application process</small>

      <h2>
        <span class="highlight">THREE</span> APPLICATION STAGES. <br />
        <span class="highlight">THREE</span> DIFFERENT CHALLENGES. <br />
        <span class="highlight">THREE</span> CHANCES TO IMPRESS.
      </h2>
    </div>

    <div class="row row--indent row--arrow">
      <p>
        We're open to everyone and we're looking for diversity of thought as
        well as unique skill-sets. Through the three-phase application process,
        you're going to be challenged in a way you never have been before. In
        short, impress in these three stages and you'll be in with a chance to
        claiming one of the SIX places on offer!
      </p>
    </div>

    <div class="row row--indent">
      <div class="application-process-list">
        <table>
          <tr>
            <td></td>
            <td>
              In a video created by you (max 1 min 30 seconds) or in writing (no
              more than 500 words), tell us why you think we should consider you
              for Pioneers, and what you think makes you stand out compared to
              everyone else. Showcase your unique skills and demonstrate to us
              why you should be hired. We want to hear all about a time that you
              were pioneering! Be creative, enthusiastic and most importantly
              pioneering with your application. We promise to review every
              application that comes through and get back to you within 14 days
              of the scheme closing.
            </td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>
              If you've managed to demonstrate that you have the creativity and
              pioneering spirit, we want to see how you apply your skills to a
              typical media task. From answering a campaign brief to tackling a
              client puzzle, we'll give you a task to wrap your head around that
              will give you a flavour for the job!
            </td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>
              The final stage of the process. It's time to meet (virtually).
              We'll introduce you to some of the team and ask you a few
              questions – some of which will have you thinking on the spot.
              You'll meet other potential Pioneers and complete group activities
              so we can see how you tackle a task. Then it's decision time for
              us..
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Slash from "@/components/Slash";

export default {
  name: "ApplicationProcess",
  components: {
    Slash,
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 768px) {
#application-process
  > div:nth-child(6)
  > div
  > table
  > tr:nth-child(1)
  > td:nth-child(1):before {
    max-height: 120px;
    margin-top: 58px;
  }

  .application-process-list table tr:nth-child(odd) td:first-of-type::before {
    max-height: 120px;
  }
}
</style>